<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="breadcrumb">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/adminis' }">超级管理员</el-breadcrumb-item>
          <el-breadcrumb-item>编辑管理员</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="form-box">
        <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px">
          <el-form-item label="姓名" prop="name">
            <el-input v-model="form.name" placeholder="请输入姓名"></el-input>
          </el-form-item>
          <el-form-item label="电话" prop="phone">
            <el-input v-model="form.phone" placeholder="请输入电话"></el-input>
          </el-form-item>
          <el-form-item label="性别" prop="sex">
            <el-select v-model="form.sex" placeholder="请选择性别">
              <el-option label="男" :value="1"></el-option>
              <el-option label="女" :value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属公司" prop="companyId">
            <el-select v-model="form.companyId" placeholder="请选择所属公司" @change="handlerSelect">
              <el-option
                v-for="item in tableData"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="菜单配置">
            <el-collapse v-model="activeNames" @change="handleChange">
              <el-collapse-item title="公司菜单配置" name="1">
                <el-tree
                  ref="menuRefs"
                  :data="dataTree"
                  show-checkbox
                  node-key="id"
                  default-expand-all
                  :check-strictly="checkStr"
                  :default-checked-keys="defaultKyes"
                  :props="defaultProps">
                </el-tree>
              </el-collapse-item>
            </el-collapse>
          </el-form-item>

          <el-form-item label="">
            <el-button type="primary" @click="onSubmit" size="small">确定</el-button>
            <el-button size="small" @click="$router.push('/adminis')">返回</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      form: {},
      rules: {
        companyId: [{ required: true, message: "必填项", trigger: "blur" }],
        name: [{ required: true, message: "必填项", trigger: "blur" }],
        phone: [{ required: true, message: "必填项", trigger: "blur" }],
        sex: [{ required: true, message: "必填项", trigger: "blur" }],
      },
      tableData: [],
      activeNames: ['1'],
      defaultKyes: [],
      dataTree: [],
      defaultProps: {
        children: 'childTree',
        label: 'title'
      },
      checkStr: false,
    };
  },
  methods: {
    handleChange(val) {},
    loadCompany() {
      this.$ajax.get("companyQueryAll").then((res) => {
        this.tableData = res.data;
      });
    },
    handlerSelect(e) {
      this.loadTree(e)
    },
    loadTree(companyId) {
      this.$ajax.post('menuCompany', {
        id: companyId
      }).then(res=> {
        this.dataTree = res.data
      })
    },
    loadDetail() {
      this.loading = true
      this.$ajax.post('staffDetail', {
        id: this.row.id
      }).then(res => {
        res.data.phoneC = res.data.phone 
        res.data.nameC = res.data.name
        res.data.roleIdC = res.data.roleId
        res.data.sexC = res.data.sex
        res.data.departmentIdC = res.data.departmentId

        res.data.companyId = this.row.companyId
        this.form = res.data
        this.defaultKyes = this.getTreeData(res.data.menuTrees)
        this.checkStr = true
        this.$nextTick(() => {
          this.$refs.menuRefs.setCheckedKeys(this.defaultKyes)
        })
        setTimeout(()=>{
          this.checkStr = false
        },500)
        this.loading = false
      }).catch(err=>{
        this.loading = false
      })
    },
    getTreeData(data) {
      let arr = [];
      if (Array.isArray(data)) {
        let sss = (d) => {
          d.forEach((item, index) => {
            arr.push(item.id)
            if (item.childTree.length > 0) {
              sss(item.childTree);
            }
          });
        };
        sss(data);
      }
      return arr;
    },
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let getCheckedKeys = this.$refs.menuRefs.getCheckedKeys()
          let getHalfCheckedKeys = this.$refs.menuRefs.getHalfCheckedKeys()
          getCheckedKeys = getCheckedKeys.concat(getHalfCheckedKeys)

          if(getCheckedKeys == '') {
            this.$message.error('请选择权限配置')
            return
          }
          let addMenuIds = []
          let deleteMenuIds = []
          // 新增权限id
          getCheckedKeys.forEach(item => {
            let adds = this.defaultKyes.find(v=>{
              return v == item
            })
            if(!adds) {
              addMenuIds.push(item)
            }
          })
          // 删除权限id
          this.defaultKyes.forEach(item => {
            let del = getCheckedKeys.includes(item)
            if(!del) {
              deleteMenuIds.push(item)
            }
          })

          let form = {
            addMenuIds: addMenuIds,
            baseInfo: {
              departmentId: this.form.departmentId != this.form.departmentIdC ? this.form.departmentId : null,
              name: this.form.name != this.form.nameC ? this.form.name : null,
              phone: this.form.phone != this.form.phoneC ? this.form.phone : null,
              roleId: this.form.roleId != this.form.roleIdC ? this.form.roleId : null,
              sex: this.form.sex != this.form.sexC ? this.form.sex : null,
            },
            deleteMenuIds: deleteMenuIds,
            id: this.form.id,
          }
          this.loading = true
          this.$ajax.post("staffUpdate", form).then((res) => {
            if (res.code == 0) {
              this.form = {};
              this.$message.success('成功');
              this.$router.push("/adminis");
            }
            this.loading = false
          }).catch(err=>{
            this.loading = false
          })
        }
      });
    }
  },
  mounted() {
    if (this.$route.params == null) {
      this.$router.push('/adminis')
      return
    } else {
      this.row = this.$route.params
    }
    this.loadCompany();
    this.loadTree(this.row.companyId)
    this.loadDetail()
  },
};
</script>

<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.breadcrumb {
  padding: 15px 10px;
  .el-breadcrumb {
    font-size: 18px;
  }
}
.form-box {
  width: 500px;
}
.el-collapse {
  border: 1px solid #DCDFE6;
  border-radius: 4px;
  overflow: hidden;
}
.el-collapse-item {
  box-shadow: 0px 0px 0px rgba(211, 211, 211, 0.5);
  border: 0px solid #eee;
  border-radius: 0px;
  margin: 0px;
  padding: 0px;
}
/deep/.el-collapse-item__header {
  height: 42px;
  padding: 0 10px;
  border-bottom: 0;
}
/deep/.el-tree-node__label {
  font-size: 15px;
  line-height: 10px;
  letter-spacing: 2px;
}
/deep/.el-tree-node__content {
  height: 35px;
}
.el-select {
    width: 100%;
}
</style>
